import * as React from 'react';
import * as Yup from 'yup';

import { Link as RouterLink } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';

import InputAdornment from '@mui/material/InputAdornment';
// icons
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';

import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
//import { useSnackbar } from 'notistack';

import useAuth from '../hooks/useAuth';
import useIsMountedRef from '../hooks/useIsMountedRef';
// utils
import axios from "../utils/axios";


const theme = createTheme();

export default function OtpForm() {
    const { verifyOtp } = useAuth();
    const isMountedRef = useIsMountedRef();
    const [alertError, setAlertError] = React.useState('');
    const [alertMessage, setAlertMessage] = React.useState('');

    React.useEffect(() => {
        setAlertMessage('Codice OTP inviato, controlla la tua casella di posta.');
        setInterval(() => {
            setAlertMessage('');
        }, 5000);
    }, []);

    const resetAlerts = () => {
        setAlertError('');
        setAlertMessage('');
    }

    const handleResendOtp = async () => {
        try {
            resetAlerts();
            const response = await axios.post(`/otp/resend`);

            if (response.data.success) {
                setAlertMessage('Un nuovo codice OTP è stato inviato, controlla la tua casella di posta.');
            }
        } catch (error) {
            if (isMountedRef.current) {
                setAlertError(error.message);
            }
        }
    }
  
    const OtpSchema = Yup.object().shape({
        otpCode: Yup.string()
            .required('Codice OTP obbligatorio.'),
    });
  
    const formik = useFormik({
        initialValues: {
            otpCode: '',
        },
        validationSchema: OtpSchema,
        onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
            try {
                resetAlerts();
                await verifyOtp(values.otpCode);

                if (isMountedRef.current) {
                    setSubmitting(false);
                }
            } catch (error) {
                console.error(error);

                if (error.status === 422) {
                    setAlertError('Invalid otp code');
                } else {
                    setAlertError(error.data.message);
                }

                if (isMountedRef.current) {
                    // Reset OTP code
                    resetForm({
                        values: {
                        otpCode: ''
                        },
                    });

                    setSubmitting(false);
                }
            }
        }
    });

    const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (

      <Container component="div">
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

            <Typography component="h1" variant="h4" sx={{ color: '#004990', fontWeight: 'bold' }}>
                Verifica Otp
            </Typography>

            <form onSubmit={formik.handleSubmit} style={{minWidth: "100%"}}>
                    {/* {errors.afterSubmit && <Alert severity="error" sx={{mt: 2, mb: 1}}>{errors.afterSubmit}</Alert>} */}
                {alertError && <Alert severity="error" sx={{mt: 2, mb: 1}}>{alertError}</Alert>}
                {alertMessage && <Alert severity="success" sx={{mt: 2, mb: 1}}>{alertMessage}</Alert>}

                <TextField
                    margin="normal"
                    variant="standard"
                    
                    fullWidth
                    id="otpCode"
                    placeholder="Codice Otp"
                    name="otpCode"
                    autoComplete="otpCode"
                    autoFocus
                    value={formik.values.otpCode}
                    onChange={formik.handleChange}
                    error={formik.touched.otpCode && Boolean(formik.errors.otpCode)}
                    helperText={formik.touched.otpCode && formik.errors.otpCode}
                    sx={styles.inputText}

                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <VpnKeyOutlinedIcon sx={{ color: "#6369E9" }} />
                            </InputAdornment>
                        ),
                        disableUnderline: true,
                    }}
                />

                <Stack direction="column" justifyContent="center" spacing={3} sx={{ mt: 4, p: "0 25%", textAlign: "center" }}>
                    <Button
                        type="submit"
                        variant="contained"
                        sx={{ p: '8px 50px', backgroundColor: '#2CA4DE' }}
                    >
                        Verifica
                    </Button>

                    <Typography 
                        variant="body2" 
                        sx={{
                            textDecoration: 'underline',
                            color: 'primary.main',
                            cursor: 'pointer',
                        }} 
                        onClick={handleResendOtp}
                    >
                        Richiedi nuovo codice
                    </Typography>
                </Stack>
            </form>
          </Box>

      </Container>
  );
}

const styles = {
  inputText: {
    backgroundColor: '#EEF2F6',
    border: '0px solid',
    p: '1rem',
    borderRadius: '10px',
  },
}