import { useState, useEffect } from "react";

import { Box } from "@mui/system";
// import  from "@material-ui/core/Grid";
import { Container, Grid } from "@material-ui/core";
import { Typography } from "@material-ui/core";
// components
import PageHeader from "../components/PageHeader";
import FrontendAppMenu from "../components/FrontendAppMenu";
import PreFooter from "../components/PreFooter";
import RoundedBox from "../components/RoundedBox";
// utils
import axios from "../utils/axios";
// paths
import { PATH_MAIN } from '../routes/paths';
// slate serializer and html parser
import ReactHtmlParser from "react-html-parser";
import SlateSerializer from "../utils/SlateSerializer";

const boxes = [
  {id: 1, imagePath: "/static/images/materiali_informativi/car_t.png", title: "Le cellule CAR-T", content: "<strong>Le cellule CAR-T</strong> sono linfociti T propri del paziente, geneticamente modificati per colpire una molecola specifica del tumore<sup>1</sup>"},
  {id: 2, imagePath: "/static/images/materiali_informativi/linfociti.png", title: "I linfociti T", content: "<strong>I linfociti T</strong> hanno un ruolo chiave nella difesa del nostro organismo<sup>2</sup>"},
  {id: 3, imagePath: "/static/images/materiali_informativi/recettore_car.png", title: "Il recettore CAR", content: "<strong>Il recettore CAR</strong> è una molecola, presente sul linfocita T, capace di riconoscere le cellule tumorali<sup>3</sup>"},
];

const paths = [
  {id: 1, imagePath: "/static/images/materiali_informativi/percorso_1.png", title: "1. Primo appuntamento", description: "La storia clinica del paziente è valutata dal medico per confermare la sua candidabilità alla terapia con CAR-T.<sup>1</sup>"},
  {id: 2, imagePath: "/static/images/materiali_informativi/percorso_2.png", title: "2. Aferesi", description: "Il paziente è sottoposto ad uno speciale prelievo di sangue in cui vengono raccolti i linfociti T.<sup>1</sup> <br> Il materiale raccolto viene spedito a centri specializzati dove avverrà l’ingegnerizzazione1<sup>1</sup> <br><br>  <strong>Il processo di aferesi richiede circa 3-4 ore<sup>1</sup></strong>"},
  {id: 3, imagePath: "/static/images/materiali_informativi/percorso_3.png", title: "3. Ingegnerizzazione", description: "Nei linfociti T viene inserito il recettore CAR, attraverso un processo controllato<sup>1</sup> <br><br>  <strong>Durante questo periodo di 3/4 settimane il paziente viene attentamente monitorato<sup>1</sup></strong>"},
  {id: 4, imagePath: "/static/images/materiali_informativi/percorso_4.png", title: "4. Chemioterapia di condizionamento", description: "Il paziente è sottoposto a chemioterapia per ridurre il numero di  linfociti<sup>1</sup>"},
  {id: 5, imagePath: "/static/images/materiali_informativi/percorso_5.png", title: "5. Somministrazione della terapia", description: "Al paziente sono somministrate le CAR-T<sup>1</sup>"},
  {id: 6, imagePath: "/static/images/materiali_informativi/percorso_6.png", title: "6. Monitoraggio", description: "Dopo il trattamento il paziente è monitorato da un team multidisciplinare <strong>formato nella gestione di possibili eventi avversi<sup>1</sup></strong> <br><br> <strong>Per le prime settimane è richiesto al paziente e di stare vicino alla struttura ospedaliera per essere monitorato<sup>1</sup></strong>"},
  {id: 7, imagePath: "/static/images/materiali_informativi/percorso_7.png", title: "7. Valutazione del tumore", description: "Dopo 1-3 mesi dalla somministrazione delle CAR-T può essere effettuato un primo controllo per la valutazione dello stato del tumore<sup>1</sup>"},
];

export default function ProgettoLayout(props) {
  const classes = { paper: "paper", root: "root fullscreen" };
  // strings
  const [strings, setStrings] = useState([]);
  const stringKeys = ['progetto_single_subtitle', 'prefooter_progetto_content', 'roundedbox_progetto_title'];

  const getStrings = async () => {
    try {
      const response = await axios.post('/strings/list/subset', { keys: stringKeys }); 
      setStrings(response.data.strings);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    getStrings();
  }, []);

  return (
    <>
      <Box>
        <FrontendAppMenu />
        <PageHeader 
          title="Il progetto"
          subtitle={strings['progetto_single_subtitle']}
        />
        <Container
          maxWidth="false"
          sx={{
            padding: {
              xs: "40px 20px !important",
              sm: "40px 35px !important",
              md: "40px 54px !important"
            },
            backgroundImage: "linear-gradient(rgba(44, 164, 222, .1), rgba(44, 164, 222, .0))",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top center",
          }}
        >
          <Grid 
            container
            maxWidth="xl"
            spacing={0}
            direction="row"
            margin="0 auto"
            sx={{
              padding: {
                sm: "20px",
                backgroundImage: "url(/static/images/services_bg.png)",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top right",
                backgroundSize: "60%"
              },
            }}
          >
            <Grid item xs={12} md={4} >
              <Box
                component="img"
                sx={{
                    width: {
                      xs: "60%",
                      md: "100%"
                    },
                    marginBottom: {
                      xs: "20px",
                      md: "0px"
                    }
                }}
                src="/static/images/progetto/main.png"
              />
            </Grid>
            
            <Grid 
              container
              item
              xs={12}
              md={8}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingLeft: {
                  md: "40px"
                }
              }}
            >
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  sx={{ 
                    mb: "60px",
                    fontWeight: "bold",
                    color: "#2CA4DE",
                    fontSize: {
                      xs: "30px",
                      sm: "35px",
                      md: "40px"
                    }
                  }}
                >
                  Cosa troverai all’interno della piattaforma?
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{ 
                    mb: "60px",
                    fontSize: "15px",
                  }}
                >
                  Nella <strong>home page</strong> verranno visualizzate le sezioni relative ai materiali informativi e i servizi di supporto. Inoltre potrai contattare il servizio di steward che ti guiderà durante la navigazione.
                </Typography>
              </Grid>
              <Grid container item xs={12}>
                <Grid item xs={2} sm={1} style={styles.stepImageWrapper}>
                  <Box component="img" style={styles.stepImagePoint} src="/static/images/progetto/step-1.png" />
                  <Box style={styles.stepImageLine}></Box>
                  {/* <Box component="img" style={styles.stepImageLine} src="/static/images/progetto/line.png" /> */}
                </Grid>
                <Grid item xs={10} sm={11} style={styles.stepContentWrapper}>
                  <Box>
                    <Typography variant="h6" style={styles.stepContentTitle}>
                      Lo steward
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={styles.stepContentDescription}>
                      Lo <strong>steward</strong> è una persona formata, a tua disposizione che:<br />
                      -<strong>Ascolta</strong> le tue esigenze e quelle del tuo caregiver,<br />
                      -<strong>Ti supporta</strong> nella navigazione all’interno della piattaforma aiutandoti a comprendere meglio il percorso e le opportunità a tua disposizione,<br />
                      -<strong>Ti affianca</strong> lungo tutto il percorso.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} sm={1} style={styles.stepImageWrapper}>
                  <Box component="img" style={styles.stepImagePoint} src="/static/images/progetto/step-2.png" />
                  <Box style={styles.stepImageLine}></Box>
                </Grid>
                <Grid item xs={10} sm={11} style={styles.stepContentWrapper}>
                  <Box>
                    <Typography variant="h6" style={styles.stepContentTitle}>
                      Materiali informativi
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={styles.stepContentDescription}>
                      Nella sezione <strong>materiali informativi</strong> troverai del materiale divulgativo da poter consultare per rispondere ai tuoi dubbi.
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={2} sm={1} style={styles.stepImageWrapper}>
                  <Box component="img" style={styles.stepImagePoint} src="/static/images/progetto/step-3.png" />
                </Grid>
                <Grid item xs={10} sm={11} style={styles.stepContentWrapper}>
                  <Box>
                    <Typography variant="h6" style={styles.stepContentTitle}>
                      Servizi
                    </Typography>
                  </Box>
                  <Box>
                    <Typography style={styles.stepContentDescription}>
                      Sarà a disposizione un pacchetto di <strong>servizi</strong> per te e il tuo caregiver: psicologo, accompagnatore, infermiere.
                    </Typography>
                  </Box>
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Container>
        <RoundedBox
          title={strings['roundedbox_progetto_title']}
          description=""
          link={null}
          sx={styles.roundedBoxStyles}
        />
      </Box>
      <PreFooter content={strings['prefooter_progetto_content']} />
    </>
  );
}

const styles = {
  stepImageWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  stepImagePoint: {
    width: "90%",
    marginBottom: "5px"
  },
  stepImageLine: {
    maxWidth: "fit-content",
    marginBottom: "5px",
    height: "100%",
    minWidth: "2px",
    backgroundColor: "#494A4C"
  },
  stepContentWrapper: {
    paddingLeft: "30px"
  },
  stepContentTitle: {
    marginBottom: "15px",
    fontWeight: "bold",
  },
  stepContentDescription: {
    marginBottom: "50px",
    fontSize: "15px",
  },
  roundedBoxStyles: {
    boxWrapper: {
      padding: {
        xs: "20px 40px", md: "20px 10px"
      }
    },
    title: {
      fontSize: {
        xs: "20px",
        md: "24px",
      },
      marginBottom: 0
    }
  }
}