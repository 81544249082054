import Grid from "@material-ui/core/Grid";

import { Outlet } from "react-router-dom";
import { Box, ThemeProvider } from "@mui/system";
import Container from "@mui/material/Container";
import { Link } from '@mui/material';

import BasicTabs from "../components/LoginRegisterTabs";

import { useState, useEffect } from "react";
import axios from "../utils/axios";
// hooks
import useAuth from "../hooks/useAuth";

import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import escapeHtml from "escape-html";
import ReactHtmlParser from "react-html-parser";
import { Text } from "slate";

const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    return string;
  }
  const children = node.children.map((n) => serialize(n)).join("");

  switch (node.type) {
    case "quote":
      return `<blockquote>${children}</blockquote>`;
      // return `<blockquote><p>${children}</p></blockquote>`;
    case "paragraph":
      return `${children}`;
      // return `<p>${children}</p>`;
    case "link":
      return `<a href="${escapeHtml(node.url)}">${children}</a>`;
    default:
      return children;
  }
};

export default function ServicesBoxes(props) {
  const { user } = useAuth();
  const classes = { paper: "paper", root: "root fullscreen" };
  const [services, getServices] = useState([]);
  const [externalUuid, setExternalUuid] = useState('');

  useEffect(() => {
    getAllServices();
    getUserExternalUuid();
  }, []);

  const getUserExternalUuid = () => {
    axios
      .post("/profile/details", {})
      .then((res) => {
        const externalUuid = res.data.external_uuid;
        setExternalUuid(externalUuid);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  const getAllServices = () => {
    const data = {
      limit: props?.items,
      orderBy: "desc"
    };

    axios
      .post("/services/list", data)
      .then((res) => {
        const allServices = res.data.services;
        getServices(allServices);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Container
      maxWidth="false"
      sx={{
        pt: "40px",
        pb: "40px"
      }}
      style={props.style}
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{
          padding: {
            xs: "0 10px",
            md: "0 30px",
          }
        }}
      >
        <Grid container maxWidth="xl" spacing={2}>
          {services.map((service, index) => {
            return (
              <Grid key={service.id} item xs={12} sm={6} md={4} lg={4} sx={{ paddingBottom: { xs: "20px", md: "30px" } }}>
                <Box
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    padding: "2rem",
                    textAlign: "center",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end"
                  }}
                >
                  <img
                    src={process.env.NODE_ENV === 'development' ? `${process.env.REACT_APP_API_BASE_URL_DEV}${service.image}` : `${process.env.REACT_APP_API_URL_PRO}${service.image}`}
                    alt={service.image}
                    loading="lazy"
                    width="100%"
                  />
                  <Typography
                    variant="h6"
                    color="secondary"
                    sx={{ fontWeight: "bold", marginBottom: "1rem", marginTop: "1rem" }}
                  >
                    {service.title}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    {ReactHtmlParser(
                      serialize({ children: JSON.parse(service.description) })
                    )}
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 5, mb: 2, fontWeight: "bold", "& a": {color: "#494A4C"} }}>
                    <Link href={externalUuid ? service.link + "#uuid=" + externalUuid : service.link + "#uuid="} target="_blank">
                      Richiedi servizio
                    </Link>
                  </Typography>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      <Box 
        sx={{ 
          textAlign: "center",
          marginBottom: "10px",
        }}
      >
        {
          props.loadMore != false ?
            <Button 
              component={Link}
              href="/main/services"
              color="primary"
              variant="blueColored"
              sx={{
                borderRadius: "50px",
                marginTop: {
                  xs: "50px",
                  md: "100px",
                }
              }}
            >
              <Typography color="white" sx={{ fontSize: '14px', padding: '4px 13px', }}>Vedi tutti i servizi</Typography>
            </Button>
          : null
        }
      </Box>
    </Container>
  );
}
